import React from "react"
import academyImage from "../../images/new/academy.png"
import academyImage1 from "../../images/new/academy-image1.jpg"
import academyImage2 from "../../images/new/academy-image2.jpg"
import pageHeader from "../../images/new/page-header.jpg"

function Academy() {

    return (
        <div className="container">
            <div className="page__header" style={{ backgroundImage: `url(${academyImage2})` }}/>
            <div className="spacing">
            <p>Owner and Director of LiaLash Beauty, meet Allison. With over 10 years of experience in the beauty industry and 
                ranked Top 10 Nations Finalist in 2018 Lashjoy Competition, Allison is now using her expertise and knowledge to 
                train the next generations of last students in the industry. </p>
            <p>She is constantly searching and looking for new beauty techniques and concepts to continuously build on her wealth 
                of experience. Allison has gained proven techniques and skills that she has developed from starting as a beginner 
                in her Lash journey to operating a fully booked business, and understands practically and intuitively what it takes 
                to become a successful Lash Artist. Our Comprehensive courses are designed to equip you with the tools and knowledge 
                that will give you confidence on your new career path. </p>
            </div>

            <img className="content__image" src={academyImage} alt="LiaLash Beauty Academy" />

            <h2>Foundations of Classic Application Course </h2>

            <p>Introducing you into the Lash world, having the foundations in place are paramount to be a successful Lash Artist. </p>

            <h3>What you will Receive</h3>
            <ul>
            <li>Comprehensive Theory & Practical learning tailored to your learning style</li>
            <li>Inside Industry knowledge - Allison’s Tips & Techniques </li>
            <li>Professional Deluxe Kit Valued over $350 </li>
            <li>Client Contraindications & Health & Safety </li>
            <li>How to effectively Style & Design Eyelashes according to Client’s profile</li>
            <li>Through Step by Step guide on Safe Eyelash Extension Prep, Application, Infill & removal</li>
            <li>Live model + Mannequin head demonstration</li>
            <li>Business Structure & Planning advice </li>
            <li>Lifetime on-going Mentoring from Allison </li>
            <li>Certificate of Completion awarded based on competence</li>
            <li>Bonus 3 Hour catch-up (3/4 months post training)</li>
            <li>+Loads more!</li>
            </ul>
 
            <h3>What’s included in your Kit</h3>
            <ul>
                <li>Lash Kit Carry Case</li>
                <li>1x High Retention Lash Adhesive </li>
                <li>1x Lash Joy Primer</li>
                <li>1x Premium Ruthie Belle Tweezers </li>
                <li>1x Premium Isolation Tweezer </li>
                <li>4x Lash Trays </li>
                <li>3x Micropore Tape </li>
                <li>100x Mascara Wands </li>
                <li>100x Lipstick application wands</li>
                <li>10x Hyrdogel Eye Pads </li>
                <li>20 Glue Flower cups </li>
                <li>2x Lash Palatte</li>
                <li>USB Charger Handheld Fan</li>
                <li>Handheld Mirror </li>
                <li>Lash &amp; Brow Scissors </li>
                <li>Manequin Head with removable Eye Lids </li>
            </ul>

            <img className="content__image" src={academyImage1} alt="LiaLash Beauty Academy" />

            <h2>Russian Volume (Handmade Fans) Course </h2>
            <p>Differentiate yourself from the rest, advance your skills to deliver quality Lash Fans handmade to perfection. Your clients will appreciate the super lightweight fluffy feel of a Glamorous look without damage to their natural lashes. </p>
            <p>Note* Strong understanding of Classic Application is required prior to commencing this course. </p>

            <h3>What you will Receive </h3>
            <ul>
                <li>Comprehensive Theory &amp; Practical learning tailored to your learning style </li>
                <li>Inside Industry knowledge - Allison’s Tips &amp; Techniques</li>
                <li>Detailed Step by Step guide on crafting Handmade Lash Fans &amp; correct application</li>
                <li>How to effectively design Eyelash Styles using Lash Fans Professional Kit Valued over $400</li>
                <li>Lash Fan creation &amp; Infill troubleshooting</li>
                <li>Live model + Mannequin head demonstration</li>
                <li>Client Contraindications &amp; Health &amp; Safety </li>
                <li>Business Planning &amp; Implemention Advice </li>
                <li>Certificate of Completion awarded based on competence </li>
                <li>Lifetime on-going Mentoring from Head Trainer Allison</li>
                <li>Bonus 3 Hour catch-up (3 months following training)</li>
                <li>+Loads more!</li>
            </ul>

            <h3>What’s included in your Kit</h3>
            <ul>
                <li>Lash Kit Carry Case</li>
                <li>1x High Retention Lash Adhesive  </li>
                <li>1x Lash Joy Primer</li>
                <li>1x Premium Volume Elite Tweezers </li>
                <li>1x Premium Isolation Tweezer </li>
                <li>4x Lash Trays </li>
                <li>3x Micropore Tape </li>
                <li>100x Mascara Wands </li>
                <li>100x Lipstick application wands</li>
                <li>10x Hyrdogel Eye Pads </li>
                <li>20 Glue Flower cups </li>
                <li>2x Lash Palatte</li>
                <li>USB Charger Handheld Fan</li>
                <li>Handheld Mirror </li>
                <li>Lash &amp; Brow Scissors </li>
                <li>Double sided tape pack </li>
                <li>Manequin Head with removable Eye Lids </li>
            </ul>
        </div>
    )
}

export default Academy;
