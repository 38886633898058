import React from "react"
import Layout from "../components/Layout"
import Academy from "../components/Academy"
import SEO from '../components/seo'

export default function AcademyPage() {
    return (
        <Layout>
          <SEO 
            title={'LiaLash Beauty Academy'} 
            description={'Allison is now using her expertise and knowledge to train the next generations of last students in the industry'} 
          />
          <h1>Academy</h1>
          <Academy />
        </Layout>
    )
}
